import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Hallan Saha Kotkan Hallansaaressa" />
    <h1>Alkuperäinen Hallan Saha Kotkan Hallansaaressa</h1>
    <p>
      1800-luvun lopulla Suomen sahateollisuuden kasvuhuuman aikaan Norjalaiset
      yrittäjät perustivat Kotkan Hallansaarelle Kymijoen suulle suuren
      höyrysahan muuttamaan järvi-suomen tukkimetsiä rahaksi. Halla Oy päätyi
      Kymi-yhtiön kautta osaksi UPM-konsernia. Sahalaitokset Hallan saarella
      ovat olleet raunioina jo vuosikymmeniä ja Kotkan kaupunki on viime vuosina
      hävittänyt niiden viimeisimpiäkin jäänteitä. Aikanaan tehdasalueen
      voimala- ja tuotantorakennukset olivat myös komeita arkkitehtonisia
      maamerkkejä, jotka näkyivät kauas Suomenlahdelle.{" "}
    </p>
    <StaticImage
      src="../images/halla_voimala.jpg"
      width={1200}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Halla Oy:n voimalan raunio"
      /*       style={{ marginBottom: `1.45rem` }} */
    />
    <Link to="/">Palaa kotisivulle</Link>
  </Layout>
)

export default SecondPage
